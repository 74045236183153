import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import type { ActionFunction, LoaderArgs, MetaFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import { Form, useLoaderData, useTransition } from '@remix-run/react';
import { useState } from 'react';
import invariant from 'tiny-invariant';
import { CustomTextEditor } from '~/components/CustomTextEditor';
import { ImageNode } from '~/components/lexicalEditor/nodes/ImageNode';
import ExampleTheme from '~/components/lexicalEditor/themes/ExampleTheme';
import { PageMargin } from '~/components/PageMargin';
import { getCustomText, setCustomText } from '~/models/custom-text.server';
import { getUser } from '~/session.server';
import { replaceHtmlImages } from '~/utils/replaceHtmlImages.server';

export async function loader({ request, params }: LoaderArgs) {
  const introductionText = await getCustomText('introduction-text');
  invariant(typeof introductionText?.value === 'string', 'El texto debe existir');
  const user = await getUser(request);
  return json({ introductionText, user });
}

type ActionData =
  | {
      newHtml: null | string;
    }
  | undefined;

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData();
  let newHtml = formData.get('newHtml');
  const errors: ActionData = {
    newHtml: newHtml ? null : 'El texto no puede estar vacío',
  };
  const hasErrors = Object.values(errors).some(errorMessage => errorMessage);
  if (hasErrors) {
    return json<ActionData>(errors);
  }

  invariant(typeof newHtml === 'string', 'El texto debe existir');

  newHtml = await replaceHtmlImages(newHtml);

  await setCustomText('introduction-text', newHtml);

  return null;
};

export default function Index() {
  const data = useLoaderData<typeof loader>();
  const { state } = useTransition();
  const loading = state === 'submitting';
  const [html, setHtml] = useState<string>('');
  const canEdit = Boolean(data.user?.isEditor);

  const editorConfig = {
    namespace: 'IntroductionPageEditor',
    // editorState: data.introductionText.value,
    editable: canEdit,
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error: Error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
    ],
  };

  return (
    <PageMargin>
      <Form method='post'>
        <main className='prose prose-slate relative mx-auto mt-2 mb-16 max-w-4xl'>
          <input type='text' name='newHtml' value={html} className='hidden' readOnly />
          <LexicalComposer initialConfig={editorConfig}>
            <CustomTextEditor
              html={data.introductionText.value}
              canEdit={canEdit}
              onChange={setHtml}
              loading={loading}
            />
          </LexicalComposer>
        </main>
      </Form>
    </PageMargin>
  );
}

export const meta: MetaFunction = () => ({
  title: 'Inicio | CSG',
  description:
    'Sitio oficial de Coordinación de Sistemas de Gestión, donde podrás encontrar los formatos de los procesos del Sistema de Calidad y otros documentos de los sistemas del Instituto Tecnológico de Saltillo.',
});
